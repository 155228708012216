import React from 'react'
import './src/styles/global.css'
import { QuickQuoteProvider } from './src/contexts/quick-quote';

// Wrap the root element with a Provider
export const wrapRootElement = ({ element }) => {
  return (
    <QuickQuoteProvider>
      {element}
    </QuickQuoteProvider>
  )
}
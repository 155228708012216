import React, { useState } from "react";

interface QuickQuoteContext {
  hasStarted: boolean;
  setHasStarted?: (value: boolean) => void;

  firstName: string;
  setFirstName?: (value: string) => void;

  lastName: string;
  setLastName?: (value: string) => void;
  email: string;
  setEmail?: (value: string) => void;

  phone: string;
  setPhone?: (value: string) => void;

  cancelQuickQuote?: () => void;
}

const initialValues: QuickQuoteContext = {
  hasStarted: false,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

export const QuickQuoteContext = React.createContext({} as QuickQuoteContext);

export const QuickQuoteProvider = ({ children }: any) => {
  const [hasStarted, setHasStarted] = useState(false);

  const [firstName, setFirstName] = useState(initialValues.firstName);
  const [lastName, setLastName] = useState(initialValues.lastName);
  const [email, setEmail] = useState(initialValues.email);
  const [phone, setPhone] = useState(initialValues.phone);

  const cancelQuickQuote = () => {
    setHasStarted(false);
    setFirstName(initialValues.firstName);
    setLastName(initialValues.lastName);
    setEmail(initialValues.email);
    setPhone(initialValues.phone);
  };

  return (
    <QuickQuoteContext.Provider
      value={{
        hasStarted,
        setHasStarted,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phone,
        setPhone,
        cancelQuickQuote,
      }}
    >
      {children}
    </QuickQuoteContext.Provider>
  );
};

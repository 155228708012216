exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-all-kitchens-tsx": () => import("./../../../src/pages/all-kitchens.tsx" /* webpackChunkName: "component---src-pages-all-kitchens-tsx" */),
  "component---src-pages-bedroom-showroom-tsx": () => import("./../../../src/pages/bedroom-showroom.tsx" /* webpackChunkName: "component---src-pages-bedroom-showroom-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-factory-tour-tsx": () => import("./../../../src/pages/factory-tour.tsx" /* webpackChunkName: "component---src-pages-factory-tour-tsx" */),
  "component---src-pages-online-consultation-tsx": () => import("./../../../src/pages/online-consultation.tsx" /* webpackChunkName: "component---src-pages-online-consultation-tsx" */),
  "component---src-pages-quick-quote-tsx": () => import("./../../../src/pages/quick-quote.tsx" /* webpackChunkName: "component---src-pages-quick-quote-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-unit-colours-tsx": () => import("./../../../src/pages/unit-colours.tsx" /* webpackChunkName: "component---src-pages-unit-colours-tsx" */),
  "component---src-templates-bedroom-style-template-tsx": () => import("./../../../src/templates/bedroom-style.template.tsx" /* webpackChunkName: "component---src-templates-bedroom-style-template-tsx" */),
  "component---src-templates-bedroom-template-tsx": () => import("./../../../src/templates/bedroom.template.tsx" /* webpackChunkName: "component---src-templates-bedroom-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blog-post.template.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-kitchen-template-tsx": () => import("./../../../src/templates/kitchen.template.tsx" /* webpackChunkName: "component---src-templates-kitchen-template-tsx" */),
  "component---src-templates-legal-page-template-tsx": () => import("./../../../src/templates/legal-page.template.tsx" /* webpackChunkName: "component---src-templates-legal-page-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page.template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-style-template-tsx": () => import("./../../../src/templates/style.template.tsx" /* webpackChunkName: "component---src-templates-style-template-tsx" */)
}

